export const permitName = "MTC-Mint-Teddy";

export const pubBaseDesigns = [
    { type: "teddy", name: 'Teddy-bear', url: "https://arweave.net/0ZP_yaIeYc4vGwMxqoJdqgOGKjZpspl7ktGUmUvNee4"},
    { type: "robot", name: 'Ro-Bear', url: "https://arweave.net/9JmggQG3JV5eLFZdvBfL3Vk7APLOmgN_WK4km7qq7fE"},
    { type: "zombie", name: 'Zom-Bear', url: "https://arweave.net/X2nzHkuIKvtucDp1UhxuHjS2OgwhyqF3wKS0U4gBlpk"}
]

export const extraPubImages = [
    { name: 'Factory', url: "https://arweave.net/-Qe3YXsw5xAyCP-ZizCHSMhTiG-yN8H3VYnphbmCjK8"},
    { name: 'Error', url: "https://arweave.net/FqTDF4Iq4Axtuv6ZG8thWrMUggx6GIcHqL-PJPJ0MfY"}
]

export const faces = [
    'Aint no snitch',
    'Angry',
    'Confused',
    'Dead serious',
    'Evil',
    'Happy',
    'Shocked',
    'Smug',
    'Teddy Smile'    
]

export const colors = ['Baby Blue',
    'Black',
    'Blood Stained',
    'Cheetah print',
    'Gold',
    'Orange',
    'Panda',
    'Polar',
    'Purple',
    'Radioactive - Green',
    'Radioactive - Pink',
    'Red',
    'Standard brown',
    'Tartan',
    "This isn't the standard brown.. Promise",
    'Tiger stripes']

export const backgrounds = ['AlterDapp Maxi',
    'Baby degens',
    'Bitcoin Maxi',
    'Cobweb',
    'Cosmos Maxi',
    'Ethereum Maxi',
    'Ew!',
    "I don't need a professional",
    'LUNA Maxi',
    "Needs patchin' up",
    'No more nightmares',
    'Secret Maxi',
    'Terra Maxi',
    'To do List',
    'Turn me on',
    'Wall plant',
    "We're watching you",
    'Wen mint?',
    'Wen moon',]

export const hands = [
    'A little off the top',
    'Airstrike inbound',
    'Alpha Pouch',
    'am Gucci',
    'Attitude adjusters',
    'Chainsaw',
    'Crowd pleaser',
    'Flame on',
    'Gameboy',
    'German piano',
    'Going somewhere?',
    'Got Bank',
    'Midnight raver',
    'Midnight raver + Nunchuck',
    'Mini Uzi',
    'Mini Uzi + Got Bank',
    'Mini-Me',
    'Mini-Me + A sharp instrument',
    'Mini-Me + am gucci',
    'Mini-Me + chainsaw',
    'Mini-Me + crowd pleaser',
    'Mini-Me + flame on',
    'Mini-Me + Gameboy',
    'Mini-Me + Gamerboy',
    'Mini-Me + German Piano',
    'Mini-Me + going somewhere',
    'Mini-Me + mini uzi',
    'Mini-Me + RPG',
    'Mini-Me + Sai swords',
    'Mini-Me + This is my boom-stick!',
    'Mini-Me + where them diamond at',
    'Mini-Me + where them diamonds at',
    'Mini-Me + WTF',
    'Mini-Me + WTF!',
    'Mini-uzi',
    'New wave axe',
    'Nunchuck',
    'Persuader',
    'RPG',
    'Sai Swords',
    'Samari Sword',
    'Sharp instrument',
    'This is my boom-stick!',
    'Whale Wallet',
    'Where them diamonds at?',
    'WTF'
]

export const heads = [
    'ATOM Cap',
    'Balaclava',
    'BSC Cap',
    'Crocodile dundee hat',
    'ETH Cap',
    'For Valhalllla!',
    'Gasmask',
    'Gasmask + Ooorah!',
    'Gasmask + Yeeehaaw!',
    'LUNA Cap',
    'Oorah!',
    'OSMO Cap',
    'Party Hat',
    'Red Bandana',
    'Red Mohawk',
    'SCRT Cap',
    'SCRT YeeeHaaw!',
    'Sneeze stopper',
    'Sneeze stopper + SCRT branded hat',
    'Squid Game Mask',
    'Squid Game Mask + take-off',
    'Take-off',
    'Terra Cap',
    'The Donald',
    'The hunter',
    'YeeeHaaw!'
]

export const bodys = [
    'ATOM Enthusiast',
    'ATOM Hoodie',
    'Black Hoodie Enthusiast',
    'Black shirt enthusiast',
    'BSC Enthusiast',
    'BSC Hoodie',
    'Builder Bear',
    'Christmas is just around the corner',
    'ETH Enthusiast',
    'ETH Hoodie',
    'Grease monkey',
    'I <3 MUM Tattoo',
    'Its chinchilla darling',
    'LUNA Enthusiast',
    'LUNA Hoodie',
    'OSMO Enthusiast',
    'OSMO Hoodie',
    'SCRT Enthusiast',
    'SCRT Hoodie',
    "Trust me, I'm a doctor",
]

export const eyewears = [
    '3D Glasses',
    'BTC Pew! Pew!',
    'Cyborg eye',
    'Cyclopes sunglasses',
    'Grouchio Marx',
    "I'm legally blind",
    'Monocle',
    'Not a real ninja',
    'Oo a pineapple',
    'Pew! Pew!',
    'Steampunk Glasses',
    'Sunny out',
    'What do you call a fish with one eye?',
]